import React from "react";
import { adminContact } from "../../utils/data";
import ContactLink from "../molecules/ContactLink";
import { HashLink } from "react-router-hash-link";

const Footer = () => {
  return (
    <>
      <div className="flex text-white justify-center items-center mt-12 md:mt-24 bg-gradient-to-r from-indigo-500 from-10% via-sky-500 via-30% to-emerald-500 to-90%">
        <div className="flex flex-col md:flex-row gap-4 p-8 justify-evenly">
          <div className="flex flex-col justify-center items-center gap-4 w-full md:w-1/4 p-5">
            <div className="text-2xl">WorkFromJob</div>
            <div className="text-center md:text-lg">
              We are providing job in telecom industry like data entry job,
              typing job, email sending job etc. Our Company is an ISO 9001:2008
              certified and process with all necessary Goverment Veriied
              Certificates.
            </div>
          </div>
          <div className="flex flex-col gap-4 w-full md:w-1/4 p-5">
            <div className="text-2xl">Reach Us</div>
            <div className="md:text-lg">
              Bandra Kurla Complex, Bandra East, Mumbai, Maharashtra 400051
            </div>
            <ContactLink className="md:text-lg">{`+91-${adminContact}`}</ContactLink>
          </div>
          <div className="flex flex-col gap-4 w-full md:w-1/4 p-5 md:text-lg">
            <div className="text-2xl">Quick Links</div>
            <div>
              <ul className="list-disc flex flex-col gap-4">
                <li>
                  <HashLink
                    smooth
                    to="#aboutus"
                    className="p-1 lg:p-2 hover:cursor-pointer hover:bg-white rounded hover:bg-opacity-20"
                  >
                    About Us
                  </HashLink>
                </li>
                <li>
                  <ContactLink
                    unstyled
                    className="p-1 lg:p-2 hover:cursor-pointer hover:bg-white rounded hover:bg-opacity-20"
                  >
                    Contact Us
                  </ContactLink>
                </li>
                <li>
                  <HashLink
                    smooth
                    to="#explore"
                    className="p-1 lg:p-2 hover:cursor-pointer hover:bg-white rounded hover:bg-opacity-20"
                  >
                    Services
                  </HashLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="p-6 px-12 pb-10 bg-black text-white flex flex-col md:flex-row gap-4 items-center justify-between">
        <div>jobshome.in - All Rights Reserved - 2023</div>
        <div className="flex gap-4 md:gap-10">
          <div>Terms of use</div>
          <div>Privacy policy</div>
        </div>
      </div>
    </>
  );
};

export default Footer;
