import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

import { adminContact } from "../../utils/data";

const message = encodeURI("Hey there!\nI want to apply for a job.");
const contact = `https://api.whatsapp.com/send?phone=91${adminContact}&text=${message}`;

const ContactLink = ({ children, className, unstyled }) => {
  return unstyled ? (
    <Link to={contact} className={className} target="_blank">
      {children}
    </Link>
  ) : (
    <motion.div
      whileHover={{ scale: 1.2 }}
      whileTap={{ scale: 0.9 }}
      transition={{ type: "spring", stiffness: 400, damping: 17 }}
    >
      <Link to={contact} className={className} target="_blank">
        {children}
      </Link>
    </motion.div>
  );
};

export default ContactLink;
