import React from "react";
import ContactLink from "../molecules/ContactLink";
import { motion } from "framer-motion";

const HeroCarousel = () => {
  return (
    <>
      <div className="flex flex-col lg:flex-row p-4 md:p-10 gap-5 lg:gap-10 items-center relative top-14 md:top-11">
        <motion.div
          className="flex w-auto h-2/3 lg:w-2/3 md:h-max flex-col justify-center items-center p-10 lg:p-20 rounded-lg bg-cover bg-gradient-to-r from-red-400 via-gray-300 to-blue-500"
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{
            duration: 0.3,
            ease: [0, 0.71, 0.2, 1.01],
            scale: {
              type: "spring",
              damping: 5,
              stiffness: 100,
              restDelta: 0.001,
            },
          }}
        >
          <div className="bg-black bg-opacity-50 p-4 md:p-8 rounded-lg gap-4 lg:gap-8 flex flex-col">
            <div className="text-xl lg:text-4xl text-white font-bold">
              <div className="text-3xl md:text-5xl">JobsHome.in </div>
              Is an Online Job search service provider
              <div className="font-extrabold text-yellow-300">
                Authorised And ISO Certified By Indian Government
              </div>
            </div>
            <div className="text-xl lg:text-4xl text-white">
              Work From Home Sms-Sending Job करके लड़के, लड़किया, गृहणियां घर बैठे
              कमाए 18000 – 46,000 महीना लैपटॉप + मोबाइल मुफ्त .
            </div>
            <div className="hidden md:flex gap-4 lg:gap-8 items-center justify-center">
              <input
                type="tel"
                placeholder="Enter your mobile number"
                className="h-12 w-72 px-4 rounded-full border border-slate-500"
              />

              <ContactLink className="px-8 text-lg py-4 flex justify-center items-center rounded-full text-white hover:cursor-pointer bg-gradient-to-r from-rose-500 via-red-400 to-red-500">
                {`Let's talk`}
              </ContactLink>
            </div>

            <ContactLink className="w-max p-2.5 px-6 text-lg flex md:hidden justify-center items-center rounded-full text-white hover:cursor-pointer bg-gradient-to-r from-rose-500 via-red-400 to-red-500">
              {`Let's talk`}
            </ContactLink>
          </div>
        </motion.div>
        <motion.div
          className="w-auto lg:w-1/3 md:h-max flex p-5 lg:p-10 rounded-lg bg-cover bg-gradient-to-r from-green-200 via-green-300 to-blue-500"
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{
            duration: 0.3,
            ease: [0, 0.71, 0.2, 1.01],
            scale: {
              type: "spring",
              damping: 5,
              stiffness: 100,
              restDelta: 0.001,
            },
          }}
          id="aboutus"
        >
          <div className="bg-black bg-opacity-50 p-4 md:p-8 rounded-lg gap-4 lg:gap-8 flex flex-col text-white justify-center">
            <div className="text-xl md:text-3xl font-semibold lg:font-bold">
              About Us
            </div>
            <div className="text-lg md:text-3xl">
              We Provide jobs in telecom industry like data entry job, typing
              job, email sending job etc.
            </div>
            <div className="text-lg md:text-2xl font-semibold lg:font-bold leading-loose">
              These are a very famous job on the Internet today. Use mobile
              phone or computer & earn ₹ 3,000 - 5,000 per day from home! We
              have launched No. 1 money making jobs website by simple Earning
              Money Online. From this webiste your job is very simple, just need
              to do copy paste. You can earn much more by doing this work.
            </div>
          </div>
        </motion.div>
      </div>
    </>
  );
};

export default HeroCarousel;
