import React from "react";

import { teamMembers } from "../../utils/data";

const AboutTeamCarousel = () => {
  return (
    <>
      <div
        className="flex justify-center items-center mt-10 md:mt-20 px-4 relative top-10 md:top-0"
        id="team"
      >
        <div className="h-max w-full lg:w-4/5  flex flex-col gap-6">
          <div className="text-3xl text-black font-extrabold text-center">
            Our Team
          </div>
          <div className="flex gap-6 overflow-x-auto pb-2" id="scrollbar">
            {teamMembers &&
              teamMembers?.map((member, idx) => {
                return (
                  <div className="flex flex-col bg-sky-500 w-auto p-4 rounded-2xl gap-4 text-white items-center justify-center">
                    <div className="flex gap-4 items-center justify-center w-max">
                      <div className="flex">
                        <img
                          src={member?.photo}
                          alt={`member ${idx} is ${member?.name}`}
                          className="w-28 h-28 md:w-36 md:h-36 rounded-full object-cover"
                        />
                      </div>
                      <div className="flex gap-3 flex-col">
                        <div className="text-2xl md:text-3xl font-bold text-center border-b-2 pb-3">
                          {member?.name}
                        </div>
                        <div className="text-xl md:text-2xl text-center">
                          {member?.bio}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutTeamCarousel;
