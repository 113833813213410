import React from "react";
import { HashLink } from "react-router-hash-link";

import BurgerMenu from "../molecules/BurgerMenu";
import ContactLink from "../molecules/ContactLink";
import { navLinks } from "../../utils/data";

const Navbar = () => {
  return (
    <>
      <div className="flex md:flex-row justify-between items-center shadow-md text-white md:px-16 bg-gradient-to-r from-gray-700 via-gray-900 to-black fixed w-full z-50">
        <a
          className="flex hover:cursor-pointer text-2xl md:text-4xl font-extrabold justify-start w-full md:w-auto pl-6"
          href={"https://www.jobshome.in/"}
        >
          JobsHome.in
        </a>
        <div className="flex items-center justify-end py-3 w-full md:w-auto gap-6">
          <div className="hidden md:flex text-base lg:text-lg gap-3">
            {navLinks &&
              navLinks?.map((item) => {
                return (
                  <HashLink
                    smooth
                    to={item?.link}
                    className="p-1 lg:p-2 hover:cursor-pointer hover:bg-white rounded hover:bg-opacity-20"
                  >
                    {item?.title}
                  </HashLink>
                );
              })}
          </div>
          <div className="flex md:hidden">
            <BurgerMenu />
          </div>
          <ContactLink className="lg:block hidden hover:cursor-pointer text-lg hover:bg-sky-900 hover:text-white px-4 py-1 border-sky-700 border">
            Apply Now
          </ContactLink>
        </div>
        <div className="fixed right-8 bottom-8 md:right-14 md:bottom-14">
          <ContactLink className="w-max p-2.5 px-6 md:p-4 text-lg md:text-xl flex justify-center items-center rounded-full text-white hover:cursor-pointer bg-[radial-gradient(ellipse_at_right,_var(--tw-gradient-stops))] from-sky-400 to-indigo-900">
            Apply Now
          </ContactLink>
        </div>
      </div>
    </>
  );
};

export default Navbar;
