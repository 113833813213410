import "./App.css";
import AboutTeamCarousel from "./components/about/AboutTeamCarousel";
import DocsRequired from "./components/docs-required/DocsRequired";
import ExploreJobs from "./components/explore-jobs/ExploreJobs";
import Footer from "./components/footer/Footer";
import HeroCarousel from "./components/hero/HeroCarousel";
import Navbar from "./components/navbar/Navbar";
import TestimonialsCarousel from "./components/testimonials/TestimonialsCarousel";

function App() {
  return (
    <>
      <Navbar />
      <HeroCarousel />
      <ExploreJobs />
      <DocsRequired />
      <AboutTeamCarousel />
      <TestimonialsCarousel />
      <Footer />
    </>
  );
}

export default App;
