export const adminContact = `8981865193`;

export const navLinks = [
  { title: "Explore", link: "#explore" },
  { title: "Docs", link: "#docs" },
  { title: "Team", link: "#team" },
  { title: "Testimonials", link: "#testimonials" },
];

export const jobs = [
  {
    title: "Data Enty Jobs",
    icon: "./copywriter.png",
    desc: "Post contents assigned by our partners on the data entry forms. Earn ₹10 per data entry form submission. Location - All India - Full Time or Part Time - INR 1500 - 2000 per day.",
  },
  {
    title: "SMS Sending Jobs",
    icon: "./spam.png",
    desc: "Process messages from our SMS sending software and earn INR 0.50 to 5 per SMS. Location - All India - Full Time or Part Time - INR 1500 - 2000 per day.",
  },
  {
    title: "Typing Jobs",
    icon: "./typing.png",
    desc: "Complete Typing Assignments without errors on the fields provided. Get INR 200 per Typing Assignment. Location - All India - Full Time or Part Time - INR 1500 - 2000 per day.",
  },
  {
    title: "Survey Jobs",
    icon: "./surveyor.png",
    desc: "Fill simple survey forms from your mobile or computer and earn up to 500 per survey. Location - All India - Full Time or Part Time - INR 2500 - 4500 per day.",
  },
  {
    title: "E-MAIL Sending Jobs",
    icon: "./mail.png",
    desc: "E-MAIL SENDING FROM HOME. EARN UP TO RS. 20,000 PER MONTH, DAILY BANK PAYOUT, ANY ONE CAN START THIS (STUDENT, HOUSEWIFE, RETIRED PERSON).",
  },
  {
    title: "Ad Posting Jobs",
    icon: "./job.png",
    desc: "Post Ads and earn money. Copy ad posting data from the member area and paste it on ad posting sites. Earn up to INR 5 per ad posted. Location - All India - Full Time or Part Time - INR 1500 - 2000 per day.",
  },
  {
    title: "Online Posting Jobs",
    icon: "./posting.png",
    desc: "WORK FROM HOME OR WORK AT HOME ANYTIME. WEEKLY PAYMENT. 100% GENUINE WORK. ONLINE POSTING WORKS AND EARN RS. 20,500 TO RS. 45,000 & MORE.",
  },
  {
    title: "Captcha Entry Jobs",
    icon: "./captcha.png",
    desc: "Solve Captcha entries, Type upper case and lower case characters as shown on the image and get INR 6 per captcha typing. Location - All India - Full Time or Part Time - INR 1500 - 2000 per day.",
  },
];

export const docsReq = [
  "Mobile Number",
  "Passport Size Photo",
  "Aadhar Card - Front & Back",
  "10th/12th Marksheet",
];

export const teamMembers = [
  { name: "Manish Chopra", bio: "Chief Manager", photo: "./team1.png" },
  { name: "Shivani Singh", bio: "Marketing Manager", photo: "./team2.png" },
  {
    name: "Gautam Kumar",
    bio: "Chief Online Support",
    photo: "./team3.png",
  },
  {
    name: "Priyanka pandey",
    bio: "Placement Manager",
    photo: "./team4.png",
  },
];

export const testimonials = [
  {
    name: "Amisha Patel",
    rating: 4.5,
    message:
      "I saw many website, they are not provide work. But here i received my work on time.Thanks for giving such a platform to earn money.",
    photo: "./client1.png",
  },
  {
    name: "Jyoti Singh",
    rating: 5,
    message:
      "Lots of home based work, service is good and easy to apply and join. i have joined this work 1 year ago and now i am getting 18,000Rs/month salary...",
    photo: "./client2.png",
  },
  {
    name: "Ranjeet Pandey",
    rating: 3.5,
    message:
      "Service is good, many options available for those who want home based works. you can easily apply and join here..",
    photo: "./client3.png",
  },
  {
    name: "Jitesh Mishra",
    rating: 4,
    message:
      "you can easily apply and join here. the process of joining is very simple. i have joined this work 1 year ago and now i am getting 18,000Rs/month salary...",
    photo: "./client4.png",
  },
];
