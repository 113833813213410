import * as React from "react";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { HashLink } from "react-router-hash-link";

import { navLinks } from "../../utils/data";

export default function BurgerMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <div
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        {open ? (
          <CloseIcon style={{ height: "34px", width: "34px" }} />
        ) : (
          <MenuIcon style={{ height: "34px", width: "34px" }} />
        )}
      </div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        className="flex mt-4"
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {navLinks &&
          navLinks?.map((item) => {
            return (
              <MenuItem onClick={handleClose}>
                <HashLink to={item?.link} smooth>
                  {item?.title}
                </HashLink>
              </MenuItem>
            );
          })}
      </Menu>
    </div>
  );
}
