import React from "react";

import { jobs } from "../../utils/data";
import ContactLink from "../molecules/ContactLink";

const ExploreJobs = () => {
  return (
    <>
      <div
        className="flex flex-col justify-center items-center gap-8 p-6 relative top-14 md:top-0"
        id="explore"
      >
        <div className="flex flex-col justify-center items-center gap-4">
          <div className="text-4xl font-extrabold">Explore Jobs</div>
          <div className="text-2xl text-center">
            A glimpse of job opportunities for you
          </div>
        </div>
        <div className="flex gap-8 flex-wrap justify-center items-start p-6">
          {jobs &&
            jobs.map((job) => {
              return (
                <div className="w-72 h-auto flex flex-col items-center justify-evenly p-3 gap-6 rounded-xl bg-gradient-to-r from-yellow-200 via-green-200 to-green-300">
                  <div className="text-3xl text-center font-bold">
                    {job?.title}
                  </div>
                  <img
                    alt={job?.title}
                    src={job?.icon}
                    className="h-1/2 w-1/2"
                  />
                  <div className="flex text-center flex-wrap text-lg leading-normal text-gray-700">
                    {job?.desc}
                  </div>
                  <ContactLink className="px-4 py-2 rounded-full text-white flex items-center justify-center hover:cursor-pointer bg-[radial-gradient(ellipse_at_right,_var(--tw-gradient-stops))] from-sky-400 to-indigo-900">
                    Apply now
                  </ContactLink>
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default ExploreJobs;
