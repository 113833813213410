import React from "react";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

import { adminContact, docsReq } from "../../utils/data";
import ContactLink from "../molecules/ContactLink";

const DocsRequired = () => {
  return (
    <>
      <div
        className="flex items-center justify-center relative top-10 md:top-0"
        id="docs"
      >
        <div className="h-max w-4/5 rounded-3xl p-6 flex flex-col items-center gap-4 hover:shadow-slate-600 hover:shadow-2xl bg-gradient-to-r from-gray-400 via-gray-600 to-blue-800">
          <div className="text-2xl md:text-3xl text-white font-extrabold text-center underline">
            Documents Required
          </div>
          <div className="text-xl md:text-2xl text-yellow-400 font-bold text-center">
            Please Summit All Documents Below WhatsApp Number.
          </div>
          <div className="flex">
            <ul className="flex flex-col list-disc gap-4">
              {docsReq &&
                docsReq?.map((doc) => {
                  return (
                    <li className="text-lg md:text-2xl text-white">{doc}</li>
                  );
                })}
            </ul>
          </div>
          <div className="text-2xl md:text-3xl text-white underline font-extrabold text-center">
            NOTE
          </div>
          <div className="text-xl md:text-2xl text-lime-400 font-bold text-center">
            After Successful Document Submission, Our Company Executive Will
            Contact You Within 24 Working Hours
          </div>
          <ContactLink className="text-xl md:text-2xl text-white text-center">
            <WhatsAppIcon />
            {` +91 ${adminContact}`}
          </ContactLink>
          <ContactLink className="bg-white rounded-sm text-black px-8 py-3 hover:cursor-pointer hover:bg-slate-200">
            Apply now
          </ContactLink>
        </div>
      </div>
    </>
  );
};

export default DocsRequired;
