import React from "react";
import Rating from "@mui/material/Rating";

import { testimonials } from "../../utils/data";

const TestimonialsCarousel = () => {
  return (
    <>
      <div
        className="flex justify-center items-center px-4 relative mt-20 md:mt-12"
        id="testimonials"
      >
        <div className="flex flex-col gap-6 h-max w-full lg:w-4/5 justify-center items-center bg-gray-800 text-white rounded-2xl p-6">
          <div className="text-2xl md:text-3xl font-extrabold text-center">
            What Our Clients Said About Us
          </div>
          <div
            className="flex gap-6 w-full md:h-full h-auto overflow-x-auto pb-5"
            id="scrollbar"
          >
            {testimonials &&
              testimonials?.map((item) => {
                return (
                  <div className="flex flex-col bg-lime-300 w-auto p-4 rounded-2xl gap-2 md:gap-4 text-gray-800 md:flex-row items-center">
                    <div className="flex flex-col gap-4 w-auto items-center justify-center">
                      <div className="flex">
                        <img
                          src={item?.photo}
                          alt={item?.name}
                          className="w-28 h-28 md:w-36 md:h-36 rounded-full object-cover"
                        />
                      </div>
                      <div className="text-xl font-bold text-center">
                        {item?.name}
                      </div>
                      <div className="hidden md:flex">
                        <Rating
                          name="read-only"
                          value={item?.rating}
                          readOnly
                          size="large"
                          precision={"0.5"}
                        />
                      </div>
                    </div>
                    <div className="flex md:hidden">
                      <Rating
                        name="read-only"
                        value={item?.rating}
                        readOnly
                        size="large"
                        precision={"0.5"}
                      />
                    </div>
                    <div className="flex p-4 text-lg md:text-xl text-black text-center w-52">
                      {item?.message}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};

export default TestimonialsCarousel;
